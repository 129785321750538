import { useContext } from 'react';

import { ChatikContext } from '@hh.ru/chatik-integration';
import { Card, Cell, CellText, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import buildChatikUrl from 'src/components/ChatikIntegration/utils/buildChatikUrl';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import { NegotiationTopic } from 'src/models/negotiationTopic.types';

interface AlreadyRespondedPlateProps {
    visible: boolean;
    resumes: Record<string, ResponseStatusResume>;
    topics: NegotiationTopic[];
    isFromPopup?: boolean;
}

const TrlKeys = {
    applied: {
        text: 'vacancy.response.alreadyResponded.applied.short',
        link: 'vacancy.response.alreadyResponded.applied.link.short',
    },
    invited: {
        text: 'vacancy.response.alreadyResponded.invitation.short',
        link: 'vacancy.response.alreadyResponded.invitation.link.short',
    },
    openChat: 'vacancy.view.response.openChat',
    chat: 'vacancy.view.response.chat',
};

const AlreadyRespondedPlate: TranslatedComponent<AlreadyRespondedPlateProps> = ({
    visible,
    topics,
    resumes,
    trls,
    isFromPopup,
}) => {
    const openChatik = useContext(ChatikContext)?.openChatik;
    const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain || '');
    const chatikOrigin = useSelector(({ chatik }) => chatik?.chatikOrigin || '');

    const filteredTopics = topics.filter((topic) => !!resumes[topic.resumeId]);

    if (!visible || !filteredTopics.length) {
        return null;
    }

    const invitedTopic = filteredTopics.find((topic) => !!topic.invited);
    const respondedTopic = invitedTopic || filteredTopics[0];
    const message = trls[invitedTopic ? TrlKeys.invited.text : TrlKeys.applied.text];
    const linkText = trls[invitedTopic ? TrlKeys.invited.link : TrlKeys.applied.link];
    const href = buildChatikUrl({
        chatId: respondedTopic.chatId,
        hhtmFromLabel: 'vacancy_response_page',
        chatikOrigin: chatikOrigin || `https://chatik.${topLevelDomain}`,
    });

    const onClickOpenChatik = () => {
        if (!(typeof openChatik === 'function')) {
            return;
        }

        openChatik({ chatId: respondedTopic.chatId, hhtmFromLabel: 'vacancy_response_page' });
    };

    const renderLink = () => {
        const isChatik = filteredTopics.length === 1 && respondedTopic && !!openChatik;

        return isChatik ? (
            <MagritteLink
                data-qa="open-vacancy-chat"
                {...(isFromPopup
                    ? { Element: 'a', href, target: '_blank' }
                    : { Element: 'button', onClick: onClickOpenChatik })}
            >
                {trls[TrlKeys.chat]}
            </MagritteLink>
        ) : (
            <MagritteLink data-qa="go-to-negotiations" Element={SPALink} to="/applicant/negotiations">
                {linkText}
            </MagritteLink>
        );
    };

    return (
        <Card style="secondary" padding={16} borderRadius={16} stretched>
            <Cell left={<InfoCircleOutlinedSize24 />} right={renderLink()}>
                <CellText data-qa="already-responded-text">{message}</CellText>
            </Cell>
        </Card>
    );
};

export default translation(AlreadyRespondedPlate);
