import Cookies from 'bloko/common/Cookies';
import elementSpy, { Bound } from 'bloko/common/elementSpy';
import urlParser from 'bloko/common/urlParser';

import { addLocationToLink } from 'src/components/VacanciesOfTheDay/Utils';

import { fetcher } from 'src/utils/fetcher';

export enum WorkInCompanyLocation {
    MainPageXsPopup = 'MAIN_PAGE_XS_POPUP',
    MainPageXsSlider = 'MAIN_PAGE_XS_SLIDER',
    MainPageBottom = 'MAIN_PAGE_BOTTOM',
    RainbowCatalog = 'RAINBOW_CATALOG',
}

export enum VacancyOfTheDayLocation {
    MainPageXsSlider = 'MAIN_PAGE_XS_SLIDER',
    MainPageBottom = 'MAIN_PAGE_BOTTOM',
    RainbowCatalog = 'RAINBOW_CATALOG',
    Article = 'ARTICLE',
    VacancySearchResult = 'VACANCY_SEARCH_RESULTS',
}

enum AdvAnalyticsShards {
    AnythingOfTheDay = '/shards/anything_of_the_day_analytics',
    RealViewBanners = '/shards/banner_real_view_analytics',
}

type Unsubscribe = () => void;

interface ClickEvent {
    clickUrl: string;
}

interface ViewEvent {
    viewUrl: string;
}

type AdvAnalyticsBody = ClickEvent | ViewEvent;

type AnythingOfTheDayAnalyticsBody = AdvAnalyticsBody & {
    location: WorkInCompanyLocation | VacancyOfTheDayLocation;
};

interface AdvAnalyticsParams {
    queryParams: void;
    body: AdvAnalyticsBody | AnythingOfTheDayAnalyticsBody;
    response: void;
}

interface TrackingBounds {
    trackingBounds?: Bound[];
    entryPercent?: never;
}

interface TrackingPercent {
    trackingBounds?: never;
    entryPercent?: number;
}

export type SpyParams = {
    elementLockTime?: number;
} & (TrackingBounds | TrackingPercent);

declare global {
    interface FetcherPostApi {
        [AdvAnalyticsShards.AnythingOfTheDay]: AdvAnalyticsParams;
        [AdvAnalyticsShards.RealViewBanners]: AdvAnalyticsParams;
    }
}

const defaultSpyParams = {
    trackingBounds: [Bound.Top],
};

export const sendAdvSpyAnalytics = (
    shardUrl: AdvAnalyticsShards,
    body: AdvAnalyticsBody | AnythingOfTheDayAnalyticsBody
): Promise<unknown> => {
    try {
        const headers = {
            type: 'application/x-www-form-urlencoded',
        };
        const blob = new Blob([urlParser.stringify({ ...body, _xsrf: Cookies.get('_xsrf') })], headers);
        const sendStatus = navigator.sendBeacon(shardUrl, blob);
        if (sendStatus) {
            return Promise.resolve();
        }
    } catch (_ignore) {} // eslint-disable-line
    return fetcher.post(shardUrl, body);
};

const spyThenSendAdvSpyAnalytics = (
    element: HTMLElement,
    shardUrl: AdvAnalyticsShards,
    body: AdvAnalyticsBody | AnythingOfTheDayAnalyticsBody,
    spyParams: SpyParams
) => {
    const { stopSpying } = elementSpy(element, {
        onShow: () => {
            stopSpying();

            return sendAdvSpyAnalytics(shardUrl, body);
        },
        ...spyParams,
    });

    return stopSpying;
};

type SendAnythingViewAnalyticsProps = {
    element: HTMLElement;
    location: WorkInCompanyLocation | VacancyOfTheDayLocation;
    spyParams?: SpyParams;
} & ViewEvent;

export const sendAnythingViewAnalytics = ({
    element,
    spyParams = defaultSpyParams,
    location,
    viewUrl,
}: SendAnythingViewAnalyticsProps): Unsubscribe =>
    spyThenSendAdvSpyAnalytics(
        element,
        AdvAnalyticsShards.AnythingOfTheDay,
        { viewUrl: addLocationToLink(viewUrl, location) },
        spyParams
    );

export const sendAnythingClickAnalytics = ({
    location,
    clickUrl,
}: Pick<SendAnythingViewAnalyticsProps, 'location'> & ClickEvent): Promise<unknown> =>
    fetcher.get(AdvAnalyticsShards.AnythingOfTheDay, {
        params: { clickUrl: addLocationToLink(clickUrl, location) },
    });

export const sendRealBannerViewAnalytics = ({
    element,
    spyParams = defaultSpyParams,
    ...body
}: Pick<SendAnythingViewAnalyticsProps, 'spyParams' | 'element' | 'viewUrl'>): Unsubscribe =>
    spyThenSendAdvSpyAnalytics(
        element,
        AdvAnalyticsShards.RealViewBanners,
        { ...body, viewUrl: body.viewUrl },
        spyParams
    );
