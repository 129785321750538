// tempexp_PORTFOLIO-40783_file

import { useCallback } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { useUrl } from 'src/hooks/useUrl';
import { UXFeedback } from 'src/utils/uxfeedback';

export default function useVacancyBenefitsUXFeedback() {
    const benefits = useSelector((state) => state.vacancyBenefits);
    const hasBenefits = benefits?.length > 0;
    const currentUrl = useUrl();

    return useCallback(() => {
        const isVacancyUrl = currentUrl.startsWith('/vacancy/');

        if (!hasBenefits || !isVacancyUrl) {
            return;
        }

        UXFeedback.sendEvent('PORTFOLIO_37970_vacancy_benefits');
    }, [hasBenefits, currentUrl]);
}
