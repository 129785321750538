import { useContext, useEffect, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ChatikContext } from '@hh.ru/chatik-integration';
import { VSpacingContainer } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { usePush } from '@hh.ru/redux-spa-middleware';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import scrollToElement from 'Utils/ScrollToElement';
import Form from 'src/components/Form';
import { useNotification } from 'src/components/Notifications/Provider';
import { ResponseError } from 'src/components/Notifications/VacancyResponseError';
import { ResponseStep } from 'src/components/VacancyResponsePopup/BottomSheet/responseSteps';
import Source from 'src/components/VacancySearchItem/types/Source';
// tempexp_PORTFOLIO-40783_next_line
import useVacancyBenefitsUXFeedback from 'src/components/VacancyView/VacancyBenefits/hooks/useVacancyBenefitsUXFeedback';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useTopLevelSite } from 'src/hooks/useSites';
import { responseStreakUpdate } from 'src/models/applicantResponseStreaks';
import { vacancyResponseUpdate } from 'src/models/applicantVacancyResponseStatuses';
import { addUserLabelsForVacancies } from 'src/models/userLabelsForVacancies/userLabels';
import { vacancyChatInfoUpdate } from 'src/models/vacancyChatInfo';
import { UserLabel } from 'src/utils/constants/userLabels';
import { fetcher } from 'src/utils/fetcher';
import { scrollToFirstError } from 'src/utils/finalForm/scrollToFirstError';
import { UXFeedback } from 'src/utils/uxfeedback';

import VacancyResponseFormAlreadyRespondedPlate from 'src/components/VacancyResponseForm/AlreadyRespondedPlate';
import VacancyResponseFormInconsistencies from 'src/components/VacancyResponseForm/Inconsistencies';
import VacancyResponseFormLetter from 'src/components/VacancyResponseForm/Letter';
import ResumeSelectorTitle from 'src/components/VacancyResponseForm/ResumeSelectorTitle';
import ResumeSelectorV2 from 'src/components/VacancyResponseForm/ResumeSelectorV2';
import { createNotification } from 'src/components/VacancyResponseForm/VacancyResponseNotification';

const scrollToRespondedBlock = (blockRef) => {
    setTimeout(() => {
        if (blockRef?.current) {
            scrollToElement(blockRef.current, {
                topOffset: 0,
                centered: false,
            });
        }
    }, 0);
};

export const getUserResumes = (responseStatus) => {
    const resumesIds = [...responseStatus.unusedResumeIds, ...responseStatus.hiddenResumeIds];
    return resumesIds.map((resumeId) => responseStatus.resumes[resumeId]);
};

const applicantActivityAction = makeSetStoreField('applicantActivity');
const resumeAdditionalDataAction = makeSetStoreField('resumeAdditionalData');

const decorators = [scrollToFirstError({ name: 'vacancy_response' })];
const NEED_LOGIN_CODE = 'need-login';
const FORM_ID = 'RESPONSE_MODAL_FORM_ID';

const ResponseForm = ({
    vacancyId,
    resumes,
    submitting,
    setSubmitting,
    onResponse,
    errorCode,
    onError,
    responseStep,
    vacancyBodyFooterNodeRef,
    vacancySource,
    needRedirect,
    isFromPopup,
    isModal,
    isBottomSheet,
    isSkipInconsistencies,
    setResponseData,
    selectedResume,
    setSelectedResume,
    postponedActions,
    isQuestionResponse,
    render = () => {},
    renderContent = () => {},
    renderSubmit = () => {},
    expandLetter,
    isLetterExpanded,
    letterValue,
    setLetterValue,
    isLetterValueEmpty,
    isLetterRequired,
    letterMaxLength,
}) => {
    const dispatch = useDispatch();
    const push = usePush();
    const openChatik = useContext(ChatikContext)?.openChatik;
    const responseStatus = useSelector((state) => {
        return state.applicantVacancyResponseStatuses[vacancyId];
    });
    const publishedForResponseResumeHash = useSelector((state) => state.publishedForResponseResumeHash);
    const countriesProfileVisibilityAgreement = useSelector((state) => {
        return state.countriesProfileVisibilityAgreement;
    });
    const topics = responseStatus.negotiations.topicList;
    const vacancy = responseStatus.shortVacancy;
    const alreadyResponded = responseStatus.usedResumeIds.length > 0;
    const { addNotification } = useNotification();

    const localstorageLetterKey = `vacancy_response_letter_${vacancyId}`;
    const localstorageQuestionKey = `vacancy_response_question_${vacancyId}`;
    const formRef = useRef();
    const { hhtmFromLabel } = useSelector(({ router }) => router.location.query);
    const hhtmSourceLabel = vacancySource === Source.RelatedVacancies ? 'suitable_vacancies' : undefined;
    const topLevelSite = useTopLevelSite();

    // tempexp_PORTFOLIO-40783_next_line
    const sendVacancyBenefitsUXFeedbackEvent = useVacancyBenefitsUXFeedback();

    useEffect(() => {
        if (errorCode === ResponseError.LetterRequired && letterValue.trim().length) {
            onError(null);
        }
    }, [errorCode, letterValue, onError]);

    const runActionFinally = (action) => {
        if (isBottomSheet) {
            postponedActions.current.push(action);
        } else {
            action();
        }
    };

    const sendResponse = (values) => {
        if (!!responseStep && responseStep !== ResponseStep.Initial) {
            // Tapping on "Save" button during `ResponseStep.Letter` step causes form submit when displaying BottomSheet.
            return;
        }

        if (!isQuestionResponse && isLetterRequired && !letterValue.trim().length) {
            onError(ResponseError.LetterRequired);
            return;
        }

        setSubmitting(true);
        onError(null);

        const skipTest = !!values?.skipTest;
        const formdata = new FormData((!skipTest && formRef.current) || undefined);
        const resumeHash = selectedResume.hash;
        const data = {
            vacancy_id: vacancyId,
            resume_hash: resumeHash,
            ignore_postponed: true,
            incomplete: selectedResume.isIncomplete,
            mark_applicant_visible_in_vacancy_country: countriesProfileVisibilityAgreement?.confirmed === true,
            letter: letterValue,
            lux: true,
            withoutTest: skipTest ? 'yes' : 'no',
            hhtmFromLabel,
            hhtmSourceLabel,
        };

        if (isQuestionResponse) {
            data.response_source = 'APPLICANT_QUESTIONS';
        }

        Object.keys(data).forEach((item) => {
            formdata.append(item, data[item]);
        });

        fetcher
            .post('/applicant/vacancy_response/popup', formdata)
            .then(
                ({ data }) => {
                    LocalStorageWrapper.removeItem(
                        isQuestionResponse ? localstorageQuestionKey : localstorageLetterKey
                    );
                    onResponse && onResponse();

                    if (data.topic_id === 'SPAM') {
                        return;
                    }

                    const chatId = data.chat_id ? Number(data.chat_id) : undefined;

                    const actions = [
                        vacancyResponseUpdate({ vacancyId, data: data.responseStatus }),
                        addUserLabelsForVacancies({
                            vacancyId,
                            labels: isQuestionResponse ? [UserLabel.Question] : [UserLabel.Response],
                        }),
                        applicantActivityAction(data.applicantActivity),
                    ];

                    if (data.applicantActivity) {
                        actions.push(applicantActivityAction(data.applicantActivity));
                    }

                    if (data.requiredAdditionalData?.length) {
                        actions.push(
                            resumeAdditionalDataAction({
                                resumeHash,
                                requiredAdditionalData: data.requiredAdditionalData,
                            })
                        );
                    }

                    if (isQuestionResponse) {
                        actions.push(vacancyChatInfoUpdate({ data: { type: 'question', chatId }, vacancyId }));
                    }

                    if (needRedirect) {
                        let params = '';
                        if ('responsesStreak' in data) {
                            params =
                                `?responsesCount=${data.responsesStreak.responsesCount}&responsesRequired=` +
                                `${data.responsesStreak.responsesRequired}`;
                        }
                        data.sendGAAnalyticsToStorage = true;
                        createNotification({
                            isQuestionResponse,
                            dispatch,
                            data,
                            employerId: vacancy.company?.id,
                            publishedForResponseResumeHash,
                            topLevelSite,
                            addNotification,
                            openChatik: () => openChatik?.({ chatId, hhtmFromLabel: 'response_notification' }),
                        });
                        push(`/vacancy/${vacancyId}${params}`);
                    } else if ('responsesStreak' in data) {
                        data.sendGAAnalyticsToStorage = false;
                        createNotification({
                            isQuestionResponse,
                            dispatch,
                            data,
                            employerId: vacancy.company?.id,
                            setResponseData: isBottomSheet ? setResponseData : undefined,
                            publishedForResponseResumeHash,
                            topLevelSite,
                            addNotification,
                            openChatik: () => openChatik?.({ chatId, hhtmFromLabel: 'response_notification' }),
                        });
                        !isQuestionResponse &&
                            actions.push(responseStreakUpdate({ vacancyId, data: data.responsesStreak }));
                    }

                    if (!isQuestionResponse) {
                        // Для повторного отклика ставим выбранным первое неиспользованное резюме
                        const unusedResumes = resumes.filter((resume) => resume.id !== selectedResume.id);
                        if (unusedResumes.length > 0) {
                            setSelectedResume(unusedResumes[0]);
                        }
                    }

                    UXFeedback.sendEvent('vacancy_response_action');
                    // tempexp_PORTFOLIO-40783_next_line
                    sendVacancyBenefitsUXFeedbackEvent();
                    runActionFinally(() => dispatch(actions));

                    if (!needRedirect) {
                        runActionFinally(() => scrollToRespondedBlock(vacancyBodyFooterNodeRef));
                    }
                },
                ({ response }) => {
                    const code = response?.data?.error;

                    if (response?.data?.type === NEED_LOGIN_CODE && response?.data?.redirect_uri) {
                        return push(response.data.redirect_uri);
                    }

                    if (response && code === 'resume-incomplete' && 'redirectUrl' in response.data) {
                        return push(response.data.redirectUrl);
                    }

                    return onError(code || 'unknown');
                }
            )
            .finally(() => {
                setSubmitting(false);
            });
    };

    const renderFormMagritte = (form) => {
        if (responseStep === ResponseStep.Letter) {
            return (
                <VacancyResponseFormLetter
                    value={letterValue}
                    setLetterValue={setLetterValue}
                    required={isLetterRequired}
                    maxLength={letterMaxLength}
                    isBottomSheet={isBottomSheet}
                    isModal={isModal}
                    expanded={isLetterExpanded}
                    expandLetter={expandLetter}
                />
            );
        }

        // Внутри БЩ используется <ResumeSelector> напрямую (на шаге ResponseStep.ResumeSelect)
        const shouldRenderResumeSelectorV2 = !isBottomSheet && resumes.length > 0;
        // Тайтл нужен только на отдельной странице отклика
        const shouldRenderResumeSelectorTitle = shouldRenderResumeSelectorV2 && !isModal;

        let resumeSelector = null;
        if (shouldRenderResumeSelectorV2) {
            resumeSelector = (
                <ResumeSelectorV2
                    responseStatus={responseStatus}
                    resumes={resumes}
                    selectedResume={selectedResume}
                    setSelectedResume={setSelectedResume}
                />
            );
        }

        const shouldRenderInconsistencies = !isBottomSheet && !isQuestionResponse && !isSkipInconsistencies;

        return (
            <VSpacingContainer default={12}>
                <VacancyResponseFormAlreadyRespondedPlate
                    visible={alreadyResponded && !submitting}
                    topics={topics}
                    resumes={responseStatus.resumes}
                    isFromPopup={isFromPopup}
                />
                <VSpacingContainer default={12}>
                    {shouldRenderResumeSelectorTitle && <ResumeSelectorTitle isQuestionResponse={isQuestionResponse} />}
                    {resumeSelector}
                </VSpacingContainer>
                <VacancyResponseFormLetter
                    value={letterValue}
                    setLetterValue={setLetterValue}
                    isQuestionLetter={isQuestionResponse}
                    required={isQuestionResponse || isLetterRequired}
                    maxLength={letterMaxLength}
                    isBottomSheet={isBottomSheet}
                    isModal={isModal}
                    expanded={isLetterExpanded}
                    expandLetter={expandLetter}
                />
                {shouldRenderInconsistencies && (
                    <VacancyResponseFormInconsistencies
                        inconsistencies={responseStatus.resumeInconsistencies}
                        selectedResumeId={selectedResume.id}
                        vacancyId={vacancyId}
                        resumes={resumes}
                    />
                )}
                {renderSubmit({ form })}
            </VSpacingContainer>
        );
    };

    const renderForm = () => (
        <FinalForm
            onSubmit={sendResponse}
            initialValues={{ skipTest: false }}
            decorators={decorators}
            render={({ handleSubmit, form }) => (
                <Form onSubmit={handleSubmit} ref={formRef} name={'vacancy_response'} id={FORM_ID}>
                    {renderContent(form)}
                    {renderFormMagritte(form)}
                </Form>
            )}
        />
    );

    return render({
        renderForm,
        formId: FORM_ID,
        isLetterTextEmpty: isLetterValueEmpty,
    });
};

export default translation(ResponseForm);
