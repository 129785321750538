import { AxiosResponse } from 'axios';

import { type ResumeAdditionalDataItem } from 'src/models/applicant/resume/additionalData/types';
import { ApplicantActivity } from 'src/models/applicantActivity';
import { ApplicantResponseStreak } from 'src/models/applicantResponseStreaks';
import { ShortVacancy } from 'src/models/applicantVacancyResponseStatuses';
import { fetcher } from 'src/utils/fetcher';

export interface VacancyResponsePostData {
    lux: boolean;
    [`ignore_postponed`]: boolean;
    [`resume_hash`]?: string;
    // TODO: выяснить почему тут number, хотя в POST-запросе строка?
    [`vacancy_id`]: number;
    letter?: string;
    [`mark_applicant_visible_in_vacancy_country`]?: boolean;
    [`response_source`]?: 'APPLICANT_QUESTIONS';
    withoutTest?: string;
    isCheckingResponseType?: boolean;
}

export interface VacancyResponseResponseData {
    ['response_label']: string;
    ['vacancy_id']: string;
    ['chat_id']: string;
    ['topic_id']: string;
    applicantActivity: ApplicantActivity;
    responseStatus: ShortVacancy;
    responsesStreak?: ApplicantResponseStreak;
    askJobSearchStatus: boolean;
    requiredAdditionalData?: ResumeAdditionalDataItem[];
}

const VACANCY_RESPONSE_POPUP_URL = '/applicant/vacancy_response/popup';

declare global {
    interface FetcherPostApi {
        [VACANCY_RESPONSE_POPUP_URL]: {
            body: VacancyResponsePostData;
            queryParams: void;
            response: VacancyResponseResponseData;
        };
    }
}

export default (postData: VacancyResponsePostData): Promise<AxiosResponse<VacancyResponseResponseData>> =>
    fetcher.postFormData(VACANCY_RESPONSE_POPUP_URL, postData);
