import { useEffect, useRef, useState } from 'react';

import responseSentElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/after_response/response_sent_element_shown';
import Button, { ButtonType, ButtonKind } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ChangeForm from 'src/components/Applicant/JobSearchStatus/ChangeForm';
import { useMarkAsRead } from 'src/components/Applicant/JobSearchStatus/useMarkAsRead';
import applicantJobSearchStatusSuccess from 'src/components/Notifications/ApplicantJobSearchStatusSuccess';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { JobSearchStatus, JobSearchStatusForm, JobSearchStatusTrigger } from 'src/models/applicantUserStatuses';

const JOB_SEARCH_STATUS_FORM_ID = JobSearchStatusForm.VacancyResponseNotification;

const TrlKeys = {
    header: 'vacancy.view.notify.response.title',
    subtitle: 'notification.applicant.job.search.status.afterResponse.subtitle',
    saveButton: 'notification.applicant.job.search.status.save',
};

interface JobSearchStatusNotificationProps {
    removeNotification: (statusChanged: boolean) => void;
    vacancyId: number;
}

const JobSearchStatusNotification: TranslatedComponent<JobSearchStatusNotificationProps> = ({
    trls,
    removeNotification,
    vacancyId,
}) => {
    const [statusSaveDisabled, setStatusSaveDisabled] = useState(true);
    const { addNotification } = useNotification();
    const notificationRef = useRef<HTMLDivElement>(null);
    const statusSetRef = useRef<boolean>(false);
    const markAsReadRef = useRef(useMarkAsRead(JobSearchStatusTrigger.AfterVacancyResponse));

    useEffect(() => {
        if (notificationRef.current) {
            responseSentElementShown(notificationRef.current, { vacancyId });
        }
    }, [vacancyId]);

    useEffect(() => {
        const markAsRead = markAsReadRef.current;
        return () => {
            if (!statusSetRef.current) {
                markAsRead();
            }
        };
    }, []);

    return (
        <>
            <NotificationHeading>{trls[TrlKeys.header]}</NotificationHeading>
            <div ref={notificationRef}>{trls[TrlKeys.subtitle]}</div>
            <VSpacing base={4} />
            <ChangeForm
                isCompact
                statusesList={[JobSearchStatus.ActiveSearch, JobSearchStatus.LookingForOffers]}
                formId={JOB_SEARCH_STATUS_FORM_ID}
                setDisabled={setStatusSaveDisabled}
                onSuccess={() => {
                    statusSetRef.current = true;
                    addNotification(applicantJobSearchStatusSuccess);
                    removeNotification(true);
                }}
            />
            <NotificationFooter>
                <Button
                    type={ButtonType.Submit}
                    kind={ButtonKind.Success}
                    form={JOB_SEARCH_STATUS_FORM_ID}
                    disabled={statusSaveDisabled}
                >
                    {trls[TrlKeys.saveButton]}
                </Button>
            </NotificationFooter>
        </>
    );
};

export default {
    Element: translation(JobSearchStatusNotification),
    kind: 'ok',
    autoClose: false,
};
