import Analytics from '@hh.ru/analytics-js';
import vacancyResponseCompleteExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete';
import vacancyResponseCompleteFeedExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete_feed';
import vacancyResponseCompleteFeedStorageExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete_feed_storage';
import vacancyResponseCompleteGaExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete_ga';
import vacancyTargetingResponseCompleteExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_targeting_response_complete';

import type { AddNotification } from 'src/components/Notifications/Provider/types';
import VacancyResponseStandardNotification from 'src/components/Notifications/VacancyResponseStandardNotification';
import { VacancyResponseResponseData } from 'src/components/VacancyResponseLink/postVacancyResponsePopup';
import {
    CPA_STORE_FIELD_BY_VACANCY_AREA_ID,
    CPA_STORE_FIELD_BY_VACANCY_ID,
    YA_DIRECT_STORE_FIELD_BY_VACANCY_ID,
} from 'src/components/VacancyTargetingViewer/const';
import { sendCpaLeadConversion } from 'src/components/VacancyTargetingViewer/vacancyTargetingViewerUtils';
import { AppDispatch } from 'src/models/appDispatch';
import { ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import { PolitenessIndex } from 'src/models/politenessIndex';
import { TopLevelSite } from 'src/models/topLevelSite';
import { fetcher } from 'src/utils/fetcher';
import {
    checkIsItemExistsInTemporaryStorage,
    checkIsItemExistsAndRemoveItInTemporaryStorage,
} from 'src/utils/temporaryStorageUtils';

import jobSearchStatusAfterResponse from 'src/components/VacancyResponseForm/JobSearchStatusNotification';
import resumeAutoHideNotification from 'src/components/VacancyResponseForm/ResumeAutoHideNotification';
import VacancyQuestionNotification from 'src/components/VacancyResponseForm/VacancyQuestionNotification';

export const VACANCY_RESPONSE = 'VACANCY_RESPONSE';

type CreateNotification = (props: {
    dispatch: AppDispatch;
    employerId?: number;
    data: VacancyResponseResponseData & {
        sendGAAnalyticsToStorage?: boolean;
        respondedWithResume?: ResponseStatusResume;
    };
    setResponseData?: (responseData: {
        readPercent: number | null;
        topicId: string;
        vacancyId: number;
        chatId?: number;
        hasResponseLetter: boolean;
    }) => void;
    publishedForResponseResumeHash: string | null;
    topLevelSite: TopLevelSite;
    addNotification: AddNotification;
    isQuestionResponse?: boolean;
    openChatik: () => void;
}) => void;

const EMPLOYER_STATS_URL = '/employer/stats';
declare global {
    interface FetcherGetApi {
        [EMPLOYER_STATS_URL]: {
            queryParams: { employerId: number };
            response: { stats: PolitenessIndex };
        };
    }
}

export const createNotification: CreateNotification = ({
    data,
    employerId,
    setResponseData,
    publishedForResponseResumeHash,
    topLevelSite, // Антон: откуда это значение получаем?
    addNotification,
    isQuestionResponse,
    openChatik,
}) => {
    const sendNotification = (readPercent: number | null) => {
        const vacancyId = Number(data.vacancy_id);
        const vacancyAreaId = data?.responseStatus?.shortVacancy?.area?.['@id'];

        if (checkIsItemExistsAndRemoveItInTemporaryStorage(YA_DIRECT_STORE_FIELD_BY_VACANCY_ID, data.vacancy_id)) {
            vacancyTargetingResponseCompleteExternal();
        }

        const cpaItemByVacancyId = checkIsItemExistsAndRemoveItInTemporaryStorage(
            CPA_STORE_FIELD_BY_VACANCY_ID,
            data.vacancy_id
        );
        if (cpaItemByVacancyId) {
            sendCpaLeadConversion(vacancyAreaId, vacancyId, cpaItemByVacancyId, 'VACANCY');
        }

        const cpaItemByVacancyAreaId = checkIsItemExistsInTemporaryStorage(
            CPA_STORE_FIELD_BY_VACANCY_AREA_ID,
            String(vacancyAreaId)
        );
        if (cpaItemByVacancyAreaId) {
            sendCpaLeadConversion(vacancyAreaId, vacancyId, cpaItemByVacancyAreaId, 'AREA');
        }

        const eventParams = { params: { product_id: vacancyId } };

        if (data.sendGAAnalyticsToStorage) {
            vacancyResponseCompleteGaExternal(eventParams);
            vacancyResponseCompleteFeedStorageExternal(eventParams);
            // tempexp_32623_next-line
            Analytics.sendEvent('b2c', 'response', 'beacon_test');
        } else {
            vacancyResponseCompleteExternal(eventParams);
            vacancyResponseCompleteFeedExternal(eventParams);
            // tempexp_32623_next-line
            Analytics.sendEvent('b2c', 'response', 'beacon_test');
        }
        if (topLevelSite === TopLevelSite.RU) {
            Analytics.addEventPixels('B2C_VACANCY_RESPONSE_COMPLETE');
        }

        const usedResume = data.respondedWithResume;

        if (isQuestionResponse) {
            addNotification(VacancyQuestionNotification, {
                props: { openChatik },
            });
            return;
        }

        if (usedResume && publishedForResponseResumeHash === usedResume.hash && usedResume.autoHideTime?.length) {
            addNotification(resumeAutoHideNotification, {
                props: {
                    resumeHash: publishedForResponseResumeHash,
                    autoHideTime: usedResume.autoHideTime[0].string,
                },
            });
            return;
        }

        const responseData = { readPercent, topicId: data.topic_id, vacancyId };
        if (setResponseData) {
            const topic = data.responseStatus.negotiations.topicList.find((n) => String(n.id) === data.topic_id);
            const chatId = data.chat_id ? Number(data.chat_id) : undefined;
            setResponseData({ ...responseData, hasResponseLetter: !!topic?.hasResponseLetter, chatId });
            return;
        }

        if (data.askJobSearchStatus) {
            addNotification(jobSearchStatusAfterResponse, { props: { vacancyId }, uniqueType: true });
        } else {
            addNotification(VacancyResponseStandardNotification, { props: { vacancyId } });
        }
    };

    if (!employerId || publishedForResponseResumeHash) {
        sendNotification(null);
    } else {
        fetcher
            .get(EMPLOYER_STATS_URL, {
                params: {
                    employerId,
                },
            })
            .then(
                ({ stats }) => sendNotification(stats.readTopicPercent),
                () => sendNotification(null)
            );
    }
};
