import { getIn, Decorator } from 'final-form';

import scrollTop from 'Utils/ScrollTop';

const SCROLL_OFFSET = 20;

interface ScrollToFirstErrorArgs {
    name: string;
    additionalInfo?: Record<string, unknown>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ScrollToFirstError = (args: ScrollToFirstErrorArgs) => Decorator<any>;

const scrollToFirstError: ScrollToFirstError =
    ({ name }) =>
    (form) => {
        let errors = {};
        const unsubscribe = form.subscribe(
            (state) => {
                const filteredSubmitErrors =
                    !state.submitErrors || typeof state.submitErrors === 'string' ? {} : state.submitErrors;
                errors = { ...state.errors, ...filteredSubmitErrors };
            },
            { errors: true, submitErrors: true }
        );

        const submit = form.submit;
        form.submit = () => {
            const findFirstAndScroll = () => {
                if (!errors || !Object.keys(errors).length) {
                    return;
                }
                const form = document.forms.namedItem(name);
                if (!form) {
                    return;
                }
                const scrollTarget =
                    [...form.querySelectorAll('[data-form-error-name]'), ...form.elements].find((el) => {
                        const element = el as HTMLElement;
                        // Если нужно прокрутить страницу не к инпуту, а к произвольному элементу,
                        // можно повесить на него `data-form-error-name="имя поля с ошибкой"`.
                        const name = element.getAttribute('data-form-error-name') || element.getAttribute('name');
                        return name && getIn(errors, name) && element.offsetWidth;
                    }) || document.querySelector('[data-form-error="true"]');
                if (!scrollTarget) {
                    return;
                }
                const top =
                    scrollTarget.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    SCROLL_OFFSET;
                scrollTop({ top });
            };
            const submitResult = submit.call(form) || Promise.resolve(undefined);
            void submitResult.then(() => setTimeout(findFirstAndScroll, 250));
            return submitResult;
        };

        return () => {
            unsubscribe();
            form.submit = submit;
        };
    };

export { scrollToFirstError };
