import { useCallback, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';

import { ChatikContext } from '@hh.ru/chatik-integration';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { usePush } from '@hh.ru/redux-spa-middleware';

import { useNotification } from 'src/components/Notifications/Provider';
import VacancyResponseErrorNotification, { ResponseError } from 'src/components/Notifications/VacancyResponseError';
import { createNotification } from 'src/components/VacancyResponseForm/VacancyResponseNotification';
// tempexp_PORTFOLIO-40783_next_line
import useVacancyBenefitsUXFeedback from 'src/components/VacancyView/VacancyBenefits/hooks/useVacancyBenefitsUXFeedback';
import { useGetResponseQuestion } from 'src/components/VacancyView/hooks/useResponseQuestion';
import { useSelector } from 'src/hooks/useSelector';
import { useTopLevelSite } from 'src/hooks/useSites';
import { responseStreakUpdate } from 'src/models/applicantResponseStreaks';
import {
    ShortVacancy,
    vacancyResponseSetFetching,
    vacancyResponseUpdate,
} from 'src/models/applicantVacancyResponseStatuses';
import { addUserLabelsForVacancies } from 'src/models/userLabelsForVacancies/userLabels';
import { vacancyChatInfoUpdate } from 'src/models/vacancyChatInfo';
import { UserLabel } from 'src/utils/constants/userLabels';
import { isServerError } from 'src/utils/fetcher';
import { UXFeedback } from 'src/utils/uxfeedback';

import postVacancyResponsePopup, {
    VacancyResponsePostData,
    VacancyResponseResponseData,
} from 'src/components/VacancyResponseLink/postVacancyResponsePopup';

const applicantActivityAction = makeSetStoreField('applicantActivity');
const resumeAdditionalDataAction = makeSetStoreField('resumeAdditionalData');

export interface HandleQuickResponseParams {
    letterMaxLength: number;
    responseStatus: ShortVacancy;
    employerId?: number;
    openChatikAfterResponse?: boolean;
    [`resume_hash`]?: string;
    [`vacancy_id`]: number;
    hhtmFromLabel: string;
    hhtmSourceLabel?: string;
    withoutNotification?: boolean;
    isQuestionQuickResponse?: boolean;
}
export type HandleQuickResponse = (params: HandleQuickResponseParams) => Promise<void>;

interface Params {
    stayOnPage?: boolean;
}

const useQuickResponse = ({ stayOnPage = true }: Params = {}): HandleQuickResponse => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const push = usePush();
    const publishedForResponseResumeHash = useSelector((state) => state.publishedForResponseResumeHash);
    const openChatik = useContext(ChatikContext)?.openChatik;
    const countriesProfileVisibilityAgreement = useSelector((state) => {
        return state.countriesProfileVisibilityAgreement;
    });
    const countriesProfileVisibilityAgreementRef = useRef(countriesProfileVisibilityAgreement);
    countriesProfileVisibilityAgreementRef.current = countriesProfileVisibilityAgreement;
    const getVacancyResponseQuestion = useGetResponseQuestion();
    const getVacancyResponseQuestionRef = useRef(getVacancyResponseQuestion);
    getVacancyResponseQuestionRef.current = getVacancyResponseQuestion;
    const topLevelSite = useTopLevelSite();

    // tempexp_PORTFOLIO-40783_next_line
    const sendVacancyBenefitsUXFeedbackEvent = useVacancyBenefitsUXFeedback();

    return useCallback(
        async ({
            letterMaxLength,
            responseStatus,
            employerId,
            openChatikAfterResponse,
            withoutNotification,
            isQuestionQuickResponse,
            ...data
        }) => {
            dispatch(vacancyResponseUpdate({ vacancyId: data.vacancy_id, data: responseStatus }));
            let result: AxiosResponse<VacancyResponseResponseData>;
            const responseQuestion = getVacancyResponseQuestionRef.current(data.vacancy_id);
            const markApplicantVisibleInVacancyCountry = countriesProfileVisibilityAgreementRef.current?.confirmed;
            const postData: VacancyResponsePostData = {
                ...data,
                lux: true,
                [`ignore_postponed`]: stayOnPage,
                [`mark_applicant_visible_in_vacancy_country`]: markApplicantVisibleInVacancyCountry,
            };
            if (responseQuestion) {
                postData.letter = responseQuestion.value;
            }

            try {
                result = await postVacancyResponsePopup(postData);
            } catch (e) {
                if (isServerError<{ error: string; redirectUrl: string }>(e)) {
                    const respData = e.response?.data;
                    const code = respData?.error;

                    if (respData && code === 'resume-incomplete' && 'redirectUrl' in respData) {
                        push(respData.redirectUrl);
                    } else {
                        addNotification(VacancyResponseErrorNotification, {
                            props: { code: code as ResponseError, letterMaxLength },
                        });
                        dispatch(vacancyResponseSetFetching({ vacancyId: data.vacancy_id, isFetching: false }));
                    }
                }
                return;
            }

            const chatId = result.data.chat_id ? Number(result.data.chat_id) : undefined;

            if (openChatikAfterResponse) {
                openChatik?.({ chatId, firstContact: !isQuestionQuickResponse });
            }

            dispatch(vacancyResponseSetFetching({ vacancyId: data.vacancy_id, isFetching: false }));

            const actions: AnyAction[] = [
                vacancyResponseUpdate({ vacancyId: data.vacancy_id, data: result.data.responseStatus }),
                addUserLabelsForVacancies({
                    vacancyId: data.vacancy_id,
                    labels: isQuestionQuickResponse ? [UserLabel.Question] : [UserLabel.Response],
                }),
            ];

            if (result.data.applicantActivity) {
                actions.push(applicantActivityAction(result.data.applicantActivity));
            }

            if (result.data.requiredAdditionalData?.length) {
                actions.push(
                    resumeAdditionalDataAction({
                        resumeHash: postData.resume_hash ?? '',
                        requiredAdditionalData: result.data.requiredAdditionalData,
                    })
                );
            }

            if (isQuestionQuickResponse) {
                actions.push(vacancyChatInfoUpdate({ vacancyId: data.vacancy_id, data: { chatId } }));
            }

            if (result.data.responsesStreak) {
                actions.push(responseStreakUpdate({ vacancyId: data.vacancy_id, data: result.data.responsesStreak }));
            }

            dispatch(actions);
            !withoutNotification &&
                createNotification({
                    isQuestionResponse: isQuestionQuickResponse,
                    dispatch,
                    data: result.data,
                    employerId,
                    publishedForResponseResumeHash,
                    topLevelSite,
                    addNotification,
                    openChatik: () => openChatik?.({ chatId, hhtmFromLabel: 'response_notification' }),
                });
            UXFeedback.sendEvent('vacancy_response_action');
            // tempexp_PORTFOLIO-40783_next_line
            sendVacancyBenefitsUXFeedbackEvent();
        },
        [
            dispatch,
            stayOnPage,
            publishedForResponseResumeHash,
            topLevelSite,
            addNotification,
            push,
            openChatik,
            sendVacancyBenefitsUXFeedbackEvent,
        ]
    );
};

export { useQuickResponse };
